import {
  GET_BAR_CODE_IMAGE,
} from '@types/actions';
import {
  SET_BAR_CODE_IMAGE,
} from '@types/mutations';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const BAR_CODE_IMAGE_ENDPOINT = '/regsvc/registry/api/v1/barcode';

export default {
  namespaced: true,
  state: {
    barCodeImage: '',
  },
  getters: {},
  mutations: {
    [SET_BAR_CODE_IMAGE](state, data) {
      state.barCodeImage = data;
    },
  },
  actions: {
    async [GET_BAR_CODE_IMAGE]({ commit, rootState }, payload) {
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const { Experimentation } = await require('@vue-util/experimentation');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, Experimentation, rootState);
        const response = await $http.post(
            `${BAR_CODE_IMAGE_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (!isError) {
          commit(SET_BAR_CODE_IMAGE, response);
        } else {
          commit(SET_BAR_CODE_IMAGE, isError);
        }
      } catch (error) {
        commit(SET_BAR_CODE_IMAGE, '');
      }
    },
  },
};
