import { dateSortFormatDate } from '@utils/SearchRegistryUtil';

const formatStatus = (result) => {
  const status = [];
  result?.forEach((item) => {
    if (item?.giftReceivedFlag) {
      status.push('GIFTS-RECEIVED');
    } else {
      status.push('GIFTS-PURCHASED');
    }
    if (item?.thankyouSentFlag) {
      status.push('THANK-YOU-SENT');
    } else {
      status.push('THANK-YOU-PENDING');
    }
  });
  return status.filter((item, index) => status.indexOf(item) === index);
};

const transformGiftTrackerResults = (result) => {
  if (result && Object.keys(result).length > 0) {
    result.registryPurchase = result?.registryPurchase?.map((item) => ({
      ...item,
      name: item?.purchaserName ? item?.purchaserName : '',
      purchased: item?.purchaseDate ? dateSortFormatDate(item?.purchaseDate) : 0,
      status: item?.registryPurchaseDetail ? formatStatus(item?.registryPurchaseDetail) : '',
      registryPurchaseDetail: item?.registryPurchaseDetail?.filter((fSubItem) => fSubItem?.transactionType?.toUpperCase() !== 'RETURN')?.map((subItem) => ({
        ...subItem,
        giftReceived: subItem?.giftReceivedFlag ? ['giftReceived'] : [],
        thankyouSent: subItem?.thankyouSentFlag ? ['thankyouSent'] : [],
        giftReceivedLoading: false,
        thankyouSentLoading: false,
        giftReceivedError: false,
        thankyouSentError: false,
      })),
    }))?.filter((fItem) => fItem?.registryPurchaseDetail?.length > 0);
    return result;
  }
  return null;
};

const transformGiftTrackerUpdateStatus = (result, payload, type, statusType) => {
  const orderItemGUIDs = payload?.transactionInfo.map((purchaseItem) => purchaseItem.registryPurchaseGUID);

  result.registryPurchase = result?.registryPurchase?.map((item) => ({
    ...item,
    registryPurchaseDetail: item?.registryPurchaseDetail?.map((subItem) => {
      if (orderItemGUIDs.indexOf(subItem?.registryPurchaseGUID) !== -1 && statusType === 'LOADING') {
        subItem[`${type}Loading`] = true;
      } else if (orderItemGUIDs.indexOf(subItem?.registryPurchaseGUID) !== -1 && statusType === 'SUCCESS') {
        subItem[`${type}Flag`] = payload?.transactionInfo[0][`${type}Flag`];
        subItem[`${type}`] = payload?.transactionInfo[0][`${type}Flag`] ? [`${type}`] : [];
        subItem[`${type}Error`] = false;
        subItem[`${type}Loading`] = false;
      } else if (orderItemGUIDs.indexOf(subItem?.registryPurchaseGUID) !== -1 && statusType === 'ERROR') {
        subItem[`${type}`] = subItem[`${type}Flag`] ? [`${type}`] : [];
        subItem[`${type}Error`] = true;
        subItem[`${type}Loading`] = false;
      }
      return subItem;
    }),
    status: formatStatus(item?.registryPurchaseDetail),
  }));
  return result;
};

export {
  formatStatus,
  transformGiftTrackerResults,
  transformGiftTrackerUpdateStatus,
};
