const CryptoJS = require('crypto-js');

export const generateHMACKey = (payload, hmacKey) => {
  const signature = JSON.stringify(payload);
  const signatureBytes = CryptoJS.HmacSHA256(signature, hmacKey);
  const hmacValue = CryptoJS.enc.Base64.stringify(signatureBytes);
  return `MAC ${hmacValue}`;
};

export default {
  generateHMACKey,
};
