export const isJSON = (data) => {
  let value = typeof data !== 'string' ? JSON.stringify(data) : data;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
  return typeof value === 'object' && value !== null;
};

export default {
  isJSON,
};
