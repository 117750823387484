/**
 * Example using code splitting and componentization. The run-time imports will cause bundle split
 * points for webpack, and as a result, load chunks on-demand (when navigating between routes).
 *
 * @see https://webpack.js.org/guides/code-splitting/#dynamic-imports
 */

import signedInUser from './utils/CheckSignedInUser';

export default () => [
  {
    name: 'Registry Landing Page',
    path: '/registry',
    component: () => import(/* webpackChunkName: "RegistryLandingPageView" */'@views/RegistryLandingPageView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Search Registry',
    path: '/registry/search',
    component: () => import(/* webpackChunkName: "SearchRegistryView" */'@views/SearchRegistryView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Wedding Registry',
    path: '/registry/wedding/create',
    beforeEnter: signedInUser,
    component: () => import(/* webpackChunkName: "CreateRegistry" */'@views/CreateRegistryView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Gift Giver Registry',
    path: '/registry/:registrantFirstName-:registrantLastName-:coregistrantFirstName?-:coregistrantLastName?/:registryId',
    alias: ['/registry/:registrantFirstName-:registrantLastName/:registryId', '/registry/-/:registryId'],
    component: () => import(/* webpackChunkName: "GiftGiverRegistryView" */'@views/GiftGiverRegistryView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Registrant View',
    path: '/registry/manage',
    beforeEnter: signedInUser,
    component: () => import(/* webpackChunkName: "RegistrantView" */'@views/RegistrantView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Registry Settings',
    path: '/registry/settings',
    beforeEnter: signedInUser,
    component: () => import(/* webpackChunkName: "RegistrySettingsView" */'@views/RegistrySettingsView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Link Registry',
    path: '/registry/link',
    beforeEnter: signedInUser,
    component: () => import(/* webpackChunkName: "LinkRegistryPage" */'@views/LinkRegistryView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Baby Registry',
    path: '/registry/baby/create',
    beforeEnter: signedInUser,
    component: () => import(/* webpackChunkName: "CreateRegistry" */'@views/CreateRegistryView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Registry Bonus Gifts',
    path: '/registry/wedding/bonusgifts',
    component: () => import(/* webpackChunkName: "CreateRegistry" */'@views/BonusGiftsView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Baby Landing Page',
    path: '/registry/baby',
    component: () => import(/* webpackChunkName: "RegistryLandingPageView" */'@views/RegistryLandingPageView'), // eslint-disable-line import/extensions
  },
  {
    name: 'Wedding Landing Page',
    path: '/registry/wedding',
    component: () => import(/* webpackChunkName: "RegistryLandingPageView" */'@views/RegistryLandingPageView'), // eslint-disable-line import/extensions
  },
];
