export const generateLogin365FingerPrint = async (PersistentLoginUtil = {}, Experimentation = {}, rootState = {}) => {
  const persistentLoginExpEnabled = await Experimentation.isRecipeSelected('2026-21');
  if (persistentLoginExpEnabled && rootState.killswitches?.persistentLoginKSEnabled && rootState.envProps.entryPoint?.toUpperCase() === 'MCOM') {
    return PersistentLoginUtil.getDeviceFingerPrintHeaders();
  }
  return {};
};

export default {
  generateLogin365FingerPrint,
};
