import { GET_SEARCH_REGISTRY_RESULTS } from '@types/actions';
import {
  SET_SEARCH_REGISTRY_RESULTS,
  SET_REGISTRY_RESULTS_TABLE_VISIBILITY,
  SET_SELECTED_REGISTRY_ID,
  SET_REGISTRY_REDIRECT_SEARCH_TERM,
  SET_REGISTRY_RESULTS_LOADING,
  SET_SEARCH_REGISTRY_ERROR_STATE,
  SET_REGISTRY_SEARCH_TERM,
  SEARCH_REGISTRY_API_ERROR_RESPONSE,
  SET_GIFTGIVER_TO_SEARCHPAGE,
  SET_SEARCH_TO_GIFTGIVERPAGE,
  SET_SELECTED_REGISTRY_GUID,
  SET_REGISTRY_SEARCH_OCCASION,
} from '@types/mutations';
import { validateRegistryId } from '@utils/SearchRegistryUtil';
import { httpGetNonProtectedAPIHeaders } from '@utils/APIUtil';

const $http = require('@vue-util/http');

const SEARCH_REGISTRY_RESULTS_ENDPOINT = '/regsvc/registry/api/v1/registry/search';

export default {
  namespaced: true,
  state() {
    return {
      searchRegistryResults: [],
      registryResultsTableVisible: false,
      registryResultsLoading: false,
      selectedRegistryId: '',
      redirectSearchTerm: '',
      errorState: false,
      registrySearchTerm: '',
      searchRegistryApiErrorResponse: null,
      giftGiverToSearchPage: false,
      searchToGiftGiverPage: false,
      selectedRegistryGuid: '',
      registrySearchOccasion: '',
    };
  },
  getters: {},
  mutations: {
    [SET_SEARCH_REGISTRY_RESULTS](state, searchRegistryResults) {
      state.searchRegistryResults = searchRegistryResults;
    },
    [SET_GIFTGIVER_TO_SEARCHPAGE](state, giftGiverToSearchPage) {
      state.giftGiverToSearchPage = giftGiverToSearchPage;
    },
    [SET_SEARCH_TO_GIFTGIVERPAGE](state, searchToGiftGiverPage) {
      state.searchToGiftGiverPage = searchToGiftGiverPage;
    },
    [SET_REGISTRY_RESULTS_TABLE_VISIBILITY](state, registryResultsTableVisible) {
      state.registryResultsTableVisible = registryResultsTableVisible;
    },
    [SET_SELECTED_REGISTRY_ID](state, selectedRegistryId) {
      state.selectedRegistryId = selectedRegistryId;
    },
    [SET_SELECTED_REGISTRY_GUID](state, selectedRegistryGuid) {
      state.selectedRegistryGuid = selectedRegistryGuid;
    },
    [SET_REGISTRY_REDIRECT_SEARCH_TERM](state, searchTerm) {
      state.redirectSearchTerm = searchTerm;
    },
    [SET_REGISTRY_RESULTS_LOADING](state, registryResultsLoading) {
      state.registryResultsLoading = registryResultsLoading;
    },
    [SET_SEARCH_REGISTRY_ERROR_STATE](state, errorState) {
      state.errorState = errorState;
    },
    [SET_REGISTRY_SEARCH_TERM](state, registrySearchTerm) {
      state.registrySearchTerm = registrySearchTerm;
    },
    [SEARCH_REGISTRY_API_ERROR_RESPONSE](state, error) {
      state.searchRegistryApiErrorResponse = error;
    },
    [SET_REGISTRY_SEARCH_OCCASION](state, occasion) {
      state.registrySearchOccasion = occasion;
    },
  },
  actions: {
    async [GET_SEARCH_REGISTRY_RESULTS]({ commit, rootState }, params) {
      commit(SET_REGISTRY_SEARCH_TERM, params.registrySearch);
      commit(SET_SEARCH_REGISTRY_ERROR_STATE, false);
      commit(SET_REGISTRY_RESULTS_TABLE_VISIBILITY, false);
      commit(SET_REGISTRY_RESULTS_LOADING, true);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_SEARCH_REGISTRY_RESULTS, []);
        commit(SET_SEARCH_REGISTRY_ERROR_STATE, true);
        commit(SET_REGISTRY_RESULTS_LOADING, false);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const response = await $http.get(
          `${SEARCH_REGISTRY_RESULTS_ENDPOINT}?orgId=${params.orgId}&${validateRegistryId(params.registrySearch) ? 'registryId' : 'name'}=${params.registrySearch}${params.sortByOccasionType ? `&sortByOccasionType=${params.sortByOccasionType}` : ''}`,
          {
            headers: httpGetNonProtectedAPIHeaders(rootState, {
              host: rootState.envProps.siteDomain,
            }),
            cancelToken: httpSource.token,
          },
        );
        clearTimeout(timer);
        if (response?.data?.registryInfo) {
          commit(SET_SEARCH_REGISTRY_RESULTS, response.data.registryInfo);
        } else {
          commit(SET_SEARCH_REGISTRY_RESULTS, []);
        }
      } catch (error) {
        clearTimeout(timer);
        commit(SET_SEARCH_REGISTRY_RESULTS, []);
        commit(SET_SEARCH_REGISTRY_ERROR_STATE, true);
        commit(SEARCH_REGISTRY_API_ERROR_RESPONSE, error);
      } finally {
        commit(SET_REGISTRY_RESULTS_TABLE_VISIBILITY, true);
        commit(SET_REGISTRY_RESULTS_LOADING, false);
      }
    },
  },
};
