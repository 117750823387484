const SET_QUICK_VIEW_PRODUCT_DETAILS = 'setQuickViewProductDetails';
const SET_REGISTRY_HOME_TAB = 'setRegistryHomeTab';
const SET_SEARCH_REGISTRY_RESULTS = 'setSearchRegistryResults';
const SET_CREATE_REGISTRY = 'setCreateregistry';
const SET_REGISTRY_RESULTS_TABLE_VISIBILITY = 'setRegistryResultsTableVisibility';
const SET_REGISTRY_INFO = 'setRegistryInfo';
const SET_REGISTRY_REDIRECT_SEARCH_TERM = 'setRegistryRedirectSearchTerm';
const SET_SELECTED_REGISTRY_ID = 'setSelectedRegistryId';
const SET_SELECTED_REGISTRY_GUID = 'setSelectedRegistryGuid';
const SET_CREATE_REGISTRY_RESPONSE = 'setCreateRegistryResponse';
const SET_REGISTRY_SETTINGS_DETAILS = 'setRegistrySettingsDetails';
const SET_PRIVATE_PREFERENCE = 'setPrivacyPreference';
const SET_EVENT_DETAILS = 'setEventDetails';
const SET_CUSTOMER_INFO = 'setCustomerInfo';
const SET_ADD_TO_BAG_RESPONSE = 'setAddToBagResponse';
const SET_REGISTRANT_GIFT_TRACKER_RESULTS = 'setRegistrantGiftTrackerResults';
const SET_REGISTRANT_MY_GIFT_RESPONSE = 'setRegistrantMyGiftResponse';
const SET_REGISTRANT_ITEM_API_RESPONSE = 'setRegistrantItemQuantitiesApiStatus';
const SET_REGISTRANT_REQUESTED_QTY = 'setRegistrantRequestedQty';
const SET_REGISTRANT_PURCHASED_QTY = 'setRegistrantPurchasedQty';
const SET_UPDATE_NUMBER_OF_GUESTS = 'setUpdateNumberOfGuests';
const SET_ERROR_VISIBLE = 'setErrorVisiblity';
const SET_REGISTRANT_GIFT_CALCULATOR = 'setRegistrantGiftCalculator';
const SET_BARCODE = 'setBarCode';
const SET_REGISTRY_RESULTS_LOADING = 'setRegistryResultsLoading';
const SET_ITEM_QTY = 'setItemQty';
const SET_UPC_DETAILS = 'setUpcDetails';
const SET_CONTACT_INFORMATION = 'setContactInformation';
const SET_SUCCESS = 'setSuccess';
const SET_ERROR = 'setError';
const SET_CONTACT_INFORMATION_SUCCESS = 'setContactInformationSuccess';
const SET_CONTACT_INFORMATION_ERROR = 'setContactInformationError';
const SET_ADDRESS = 'setArress';
const SET_ADDRESS_SUCCESS = 'setAddressSuccess';
const SET_ADDRESS_ERROR = 'setAddressError';
const SET_LOCATION_SERVICE_RESPONSE = 'setLocationServiceResponse';
const SET_LOCATION_ERROR_STATE = 'setLocationErrorState';
const SET_CREATE_REGISTRY_ERROR_STATE = 'setCreateRegistryErrorState';
const SET_REGISTRANT_MY_GIFT_LOADER = 'setRegistrantMyGiftLoader';
const SET_LOCATIONS_AVAILABLE = 'setLocationsAvailable';
const SET_LINK_REGISTRY = 'setLinkRegistry';
const SET_LINK_ERROR_STATE = 'setLinkErrorState';
const SET_CUSTOMER_INFO_LOADING = 'setCustomerInfoLoading';
const SET_REGISTRY_SETTINGS_LOADER = 'setRegistrySettingsLoader';
const SET_GIFT_GIVER_VIEW_LOADING = 'setGiftGiverViewLoading';
const SET_REGISTRANT_GIFT_TRACKER_LOADER = 'setRegistrantGiftTrackerLoader';
const SET_REGISTRY_ITEM_STATUS = 'setRegistryItemStatus';
const SET_REGISTRY_ID_STATUS = 'setRegistryIdStatus';
const SET_GIFT_GIVER_VIEW_ERROR = 'setGiftGiverViewError';
const SET_HEADER_BANNER_ERROR = 'setHeaderBannerError';
const SET_REGISTRANT_HEADER_BANNER_LOADING = 'setRegistrantHeaderBannerLoading';
const UPDATE_PREFERENCE_STATUS = 'updatePreferenceStatus';
const SET_UPDATED_PRODUCTS = 'setUpdatedProducts';
const SET_QUICK_VIEW_SAVE_ERROR_STATE = 'setQuickViewSaveErrorState';
const SET_STORE_ERROR_STATE = 'setStoreErrorState';
const SET_SEARCH_REGISTRY_ERROR_STATE = 'setSearchRegistryErrorState';
const SET_REGISTRY_SEARCH_TERM = 'setRegistrySearchTerm';
const SET_CANVAS = 'setCanvas';
const SET_CANVAS_ERROR_STATE = 'setCanvasErrorState';
const SET_QUICK_VIEW_PRODUCT_DETAILS_META = 'setQuickViewProductDetailsMeta';
const SET_PRIVACY_PREFERENCE_SUCCESS = 'setPrivacyPreferenceSuccess';
const SET_PRIVACY_PREFERENCE_FAILURE = 'setPrivacyPreferenceFailure;';
const SET_GIFT_TRACKER_RESULTS_ERROR = 'setGiftTrackerResultsError';
const SET_GIFT_TRACKER_UPDATE_ERROR = 'setGiftTrackerUpdateError';
const SET_REMOVE_ITEM_API_ERROR_RESPONSE = 'setRemoveItemApiResponse';
const SET_PRODUCT_DETAILS_API_ERROR_RESPONSE = 'setProductDetailsApiResponse';
const SET_GIFT_GIVER_API_ERROR_RESPONSE = 'setGiftGiverApiErrorResponse';
const SET_REGISTRANT_MY_GIFT_API_ERROR_RESPONSE = 'setRegistrantMyGiftApiErrorResponse';
const SET_REGISTRANT_HOM_TAB_API_ERROR_RESPONSE = 'setRegistrantHomeTabApiErrorResponse';
const SET_CANVAS_API_ERROR_RESPONSE = 'setCanvasApiErrorResponse';
const SET_CUSTOMER_INFO_API_ERROR_RESPONSE = 'setCustomerInfoApiErrorResponse';
const SET_CREATE_REGISTRY_ERROR_RESPONSE = 'setCreateRegistryApiErrorResponse';
const SET_LOCATION_API_ERROR_RESPONSE = 'setLocationApiErrorResponse';
const SEARCH_REGISTRY_API_ERROR_RESPONSE = 'setSearchRegistryApiErrorResponse';
const SET_REGISTRYINFO_API_ERROR = 'setRegistryInfoApiError';
const SET_UPDATE_REGISTRANT_API_ERROR = 'setUpdateRegistrantApiError';
const SET_UPDATE_REGISTRY_SETTING_API_ERROR = 'seUpdatetRegistrySettingApiError';
const UPDATE_REGISTRANT_ADDRESS_API_ERROR = 'updateRegistantAddressApiError';
const SET_CREATE_REGISTRY_LOADING = 'setCreateRegistryLoading';
const SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR = 'setShareRegistryVisibilityStatusApiError';
const UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR = 'updateRegistryEventDetailApiError';
const SET_HEADER_BANNER_API_ERROR_RESPONSE = 'headerBannerApiErrorResponse';
const SET_LINK_REGISTRY_API_ERROR_DESCRIPTION = 'setLinkRegistryApiErrorDescription';
const SET_REGISTRANT_MY_GIFT_API_ERROR_DESCRIPTION = 'registrantMyGiftApiErrorDescription';
const SET_LINK_REGISTRY_API_ERROR_RESPONSE = 'setLinkRegistryApiErrorResponse';
const SET_REGISTRYINFO_API_ERROR_DESCRIPTION = 'setRegistryInfoApiErrorDescription';
const SET_UPDATE_REGISTRANT_API_ERROR_DESCRIPTION = 'setUpdateRegistrantApiErrorDescription';
const SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION = 'setUpdatetRegistrySettingApiErrorDescription';
const SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION = 'setUpdateRegistantAddressApiErrorDescription';
const SET_UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR_DESCRIPTION = 'setUpdateRegistryEventDetailApiErrorDescription';
const SET_CREATE_REGISTRY_API_ERROR_DESCRIPTION = 'setCreateRegistryApiErrorDescription';
const SET_EDIT_GUEST_COUNT_API_ERROR_RESPONSE = 'editGuestCountApiErrorResponse';
const SET_EDIT_GUEST_COUNT_API_ERROR = 'editGuestCountApiError';
const SET_EDIT_GUEST_COUNT_API_ERROR_DESCRIPTION = 'editGuestCountApiErrorDescription';
const SET_HEADER_BANNER_ERROR_DESCRIPTION = 'setHeaderBannerErrorDescription';
const SET_REGISTRANT_HOME_TAB_API_ERROR_STATUS_CODE = 'setRegistrantHomeTabAPIErrorStatusCode';
const SET_REGISTRANT_MY_GIFT_API_ERROR_STATUS_CODE = 'setRegistrantMyGiftAPiErrorStatusCode';
const SET_GIFT_TRACKER_UPDATE_STATUS_ERROR = 'setGiftTrackerUpdateStatusError';
const SET_UPDATE_REGISTRANT_ADDRESS_API_STATUS_ERROR = 'setUpdateRegistrantAddressAPIStatusError';
const SET_CREATE_REGISTRY_BACKEND_ERROR_STATE = 'setCreateRegistryBackendErrorState';
const SET_GIFTGIVER_TO_SEARCHPAGE = 'setGiftGiverToSeachPage';
const SET_SEARCH_TO_GIFTGIVERPAGE = 'setSearchToGiftGiverPage';
const UPDATE_GIFTS_PURCHASED = 'updateGiftsPurchased';
const SET_REGISTRY_PRIVACY_CHANGE_ACTION = 'setRegistryPrivacyChangeAction';
const UPDATE_PRIVACY_PREFERENCE_LOADER = 'updatePrivacyPreferenceLoader';
const SET_PRIVACY_PREFERENCE_API_TIMEOUT = 'setPrivacyPreferenceApiTimeout';
const SET_GIFT_CARD_OPTIONS = 'setGiftCardOptions';
const SET_GIFT_CARD_OPTIONS_UPDATE_ERROR = 'setUpdateGiftCardOptionsError';
const SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS = 'setUpdateGiftCardOptionsSuccess';
const SET_GIFT_CARD_OPTIONS_UPDATE_ERROR_DESCRIPTION = 'setUpdateGiftCardOptionsErrorDescription';
const SET_GIFT_CARD_AMOUNT = 'setGiftCardAmount';
const SET_GIFT_CARD_MESSAGE = 'setGiftCardMessage';
const SET_REFRESH_REGISTRANT_MY_GIFT = 'setRefreshRegistrantMyGift';
const SET_IN_VALID_GIFT_CARD_AMOUNT = 'setInValidGiftCardAmount';
const SET_GIFT_CARD_DELIVERY_OPTION = 'setGiftCardDeliveryOption';
const SET_GIFT_CARD_KILLSWITCH = 'setGiftCardKillSwitch';
const SET_E_GIFT_CARD_KILLSWITCH = 'setEGiftCardKillSwitch';
const SET_ORDER_STATUS_KILLSWITCH = 'setOrderStatusKillswitch';
const SET_BAR_CODE_IMAGE = 'setBarCodeImage';
const SET_BABY_REGISTRY_KILL_SWITCH = 'setBabyRegistryKillSwitch';
const SET_IS_AEM_ENABLED_FOR_REGISTRY_KILL_SWITCH = 'setIsAEMEnabledForRegistryKillSwitch';
const SET_CONTEXTUAL_WIDGET_KILLSWITCH = 'setSalesFloorFloatingContextualWidget';
const SET_REGISTRY_STATUS_OCCASION = 'setRegistryStatusOccasion';
const SET_PROS_COLLECTION_ENABLED_KILLSWITCH = 'setProsCollectionEnabledKillSwitch';
const SET_REGISTRY_SEARCH_OCCASION = 'setRegistrySearchOccasion';
const SET_SHOPPING_MODE_FOR_AEM_AS_SITE_ENABLED_KILLSWITCH = 'setShoppingModeForAemAsSiteEnabledKillSwitch';
const SET_CREATE_REGISTRY_MANUAL_SURVEY_ENABLED_KILLSWITCH = 'setCreateRegistryManualSurveyEnabledKillSwitch';

export {
  SET_QUICK_VIEW_PRODUCT_DETAILS,
  SET_REGISTRY_HOME_TAB,
  SET_SEARCH_REGISTRY_RESULTS,
  SET_CREATE_REGISTRY,
  SET_REGISTRY_RESULTS_TABLE_VISIBILITY,
  SET_REGISTRY_INFO,
  SET_SELECTED_REGISTRY_ID,
  SET_CREATE_REGISTRY_RESPONSE,
  SET_REGISTRY_SETTINGS_DETAILS,
  SET_REGISTRY_REDIRECT_SEARCH_TERM,
  SET_PRIVATE_PREFERENCE,
  SET_EVENT_DETAILS,
  SET_CUSTOMER_INFO,
  SET_ADD_TO_BAG_RESPONSE,
  SET_REGISTRANT_GIFT_TRACKER_RESULTS,
  SET_REGISTRANT_GIFT_CALCULATOR,
  SET_REGISTRANT_MY_GIFT_RESPONSE,
  SET_REGISTRANT_ITEM_API_RESPONSE,
  SET_REGISTRANT_REQUESTED_QTY,
  SET_REGISTRANT_PURCHASED_QTY,
  SET_UPDATE_NUMBER_OF_GUESTS,
  SET_ERROR_VISIBLE,
  SET_BARCODE,
  SET_CONTACT_INFORMATION,
  SET_SUCCESS,
  SET_ERROR,
  SET_CONTACT_INFORMATION_SUCCESS,
  SET_CONTACT_INFORMATION_ERROR,
  SET_REGISTRY_RESULTS_LOADING,
  SET_ADDRESS,
  SET_ADDRESS_SUCCESS,
  SET_ADDRESS_ERROR,
  SET_ITEM_QTY,
  SET_UPC_DETAILS,
  SET_LOCATION_SERVICE_RESPONSE,
  SET_LOCATION_ERROR_STATE,
  SET_LOCATIONS_AVAILABLE,
  SET_CREATE_REGISTRY_ERROR_STATE,
  SET_LINK_REGISTRY,
  SET_LINK_ERROR_STATE,
  SET_CUSTOMER_INFO_LOADING,
  SET_REGISTRANT_MY_GIFT_LOADER,
  SET_REGISTRY_SETTINGS_LOADER,
  SET_GIFT_GIVER_VIEW_LOADING,
  SET_REGISTRANT_GIFT_TRACKER_LOADER,
  SET_REGISTRY_ITEM_STATUS,
  SET_REGISTRY_ID_STATUS,
  SET_GIFT_GIVER_VIEW_ERROR,
  SET_HEADER_BANNER_ERROR,
  SET_REGISTRANT_HEADER_BANNER_LOADING,
  UPDATE_PREFERENCE_STATUS,
  SET_UPDATED_PRODUCTS,
  SET_QUICK_VIEW_SAVE_ERROR_STATE,
  SET_STORE_ERROR_STATE,
  SET_SEARCH_REGISTRY_ERROR_STATE,
  SET_REGISTRY_SEARCH_TERM,
  SET_CANVAS,
  SET_CANVAS_ERROR_STATE,
  SET_QUICK_VIEW_PRODUCT_DETAILS_META,
  SET_PRIVACY_PREFERENCE_SUCCESS,
  SET_PRIVACY_PREFERENCE_FAILURE,
  SET_GIFT_TRACKER_RESULTS_ERROR,
  SET_GIFT_TRACKER_UPDATE_ERROR,
  SET_REMOVE_ITEM_API_ERROR_RESPONSE,
  SET_PRODUCT_DETAILS_API_ERROR_RESPONSE,
  SET_GIFT_GIVER_API_ERROR_RESPONSE,
  SET_REGISTRANT_MY_GIFT_API_ERROR_RESPONSE,
  SET_REGISTRANT_HOM_TAB_API_ERROR_RESPONSE,
  SET_CANVAS_API_ERROR_RESPONSE,
  SET_CUSTOMER_INFO_API_ERROR_RESPONSE,
  SET_CREATE_REGISTRY_ERROR_RESPONSE,
  SET_LOCATION_API_ERROR_RESPONSE,
  SEARCH_REGISTRY_API_ERROR_RESPONSE,
  SET_REGISTRYINFO_API_ERROR,
  SET_UPDATE_REGISTRANT_API_ERROR,
  SET_UPDATE_REGISTRY_SETTING_API_ERROR,
  UPDATE_REGISTRANT_ADDRESS_API_ERROR,
  SET_SHARE_REGISTRY_VISIBILITY_STATUS_API_ERROR,
  UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR,
  SET_HEADER_BANNER_API_ERROR_RESPONSE,
  SET_CREATE_REGISTRY_LOADING,
  SET_LINK_REGISTRY_API_ERROR_DESCRIPTION,
  SET_LINK_REGISTRY_API_ERROR_RESPONSE,
  SET_REGISTRYINFO_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRANT_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRY_SETTING_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRANT_ADDRESS_API_ERROR_DESCRIPTION,
  SET_UPDATE_REGISTRANT_EVENT_DETAILS_API_ERROR_DESCRIPTION,
  SET_CREATE_REGISTRY_API_ERROR_DESCRIPTION,
  SET_EDIT_GUEST_COUNT_API_ERROR_RESPONSE,
  SET_REGISTRANT_MY_GIFT_API_ERROR_DESCRIPTION,
  SET_EDIT_GUEST_COUNT_API_ERROR,
  SET_EDIT_GUEST_COUNT_API_ERROR_DESCRIPTION,
  SET_HEADER_BANNER_ERROR_DESCRIPTION,
  SET_REGISTRANT_HOME_TAB_API_ERROR_STATUS_CODE,
  SET_REGISTRANT_MY_GIFT_API_ERROR_STATUS_CODE,
  SET_GIFT_TRACKER_UPDATE_STATUS_ERROR,
  SET_UPDATE_REGISTRANT_ADDRESS_API_STATUS_ERROR,
  SET_CREATE_REGISTRY_BACKEND_ERROR_STATE,
  SET_GIFTGIVER_TO_SEARCHPAGE,
  SET_SEARCH_TO_GIFTGIVERPAGE,
  UPDATE_GIFTS_PURCHASED,
  SET_REGISTRY_PRIVACY_CHANGE_ACTION,
  UPDATE_PRIVACY_PREFERENCE_LOADER,
  SET_PRIVACY_PREFERENCE_API_TIMEOUT,
  SET_GIFT_CARD_OPTIONS,
  SET_GIFT_CARD_OPTIONS_UPDATE_ERROR,
  SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS,
  SET_GIFT_CARD_OPTIONS_UPDATE_ERROR_DESCRIPTION,
  SET_GIFT_CARD_AMOUNT,
  SET_GIFT_CARD_MESSAGE,
  SET_REFRESH_REGISTRANT_MY_GIFT,
  SET_IN_VALID_GIFT_CARD_AMOUNT,
  SET_GIFT_CARD_DELIVERY_OPTION,
  SET_GIFT_CARD_KILLSWITCH,
  SET_E_GIFT_CARD_KILLSWITCH,
  SET_ORDER_STATUS_KILLSWITCH,
  SET_BAR_CODE_IMAGE,
  SET_BABY_REGISTRY_KILL_SWITCH,
  SET_SELECTED_REGISTRY_GUID,
  SET_IS_AEM_ENABLED_FOR_REGISTRY_KILL_SWITCH,
  SET_CONTEXTUAL_WIDGET_KILLSWITCH,
  SET_REGISTRY_STATUS_OCCASION,
  SET_PROS_COLLECTION_ENABLED_KILLSWITCH,
  SET_REGISTRY_SEARCH_OCCASION,
  SET_SHOPPING_MODE_FOR_AEM_AS_SITE_ENABLED_KILLSWITCH,
  SET_CREATE_REGISTRY_MANUAL_SURVEY_ENABLED_KILLSWITCH,
};
