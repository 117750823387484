import { Cookie } from '@common-vue/util/src/modules/cookie';

export default function signedInUser(to, from, next) {
  if (typeof document === 'object') {
    const isSignedIn = !!Number(Cookie.get('SignedIn'));
    Cookie.set('FORWARDPAGE_KEY', window.location.href);
    if (!isSignedIn) {
      window.location.assign('/account/signin');
    } else {
      next();
    }
  } else {
    next();
  }
}
