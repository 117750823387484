import {
  SET_CREATE_REGISTRY,
  SET_CREATE_REGISTRY_RESPONSE,
  SET_LOCATION_SERVICE_RESPONSE,
  SET_LOCATION_ERROR_STATE,
  SET_LOCATIONS_AVAILABLE,
  SET_CREATE_REGISTRY_ERROR_STATE,
  SET_CREATE_REGISTRY_BACKEND_ERROR_STATE,
  SET_REGISTRY_ID_STATUS,
  SET_CREATE_REGISTRY_ERROR_RESPONSE,
  SET_LOCATION_API_ERROR_RESPONSE,
  SET_CREATE_REGISTRY_LOADING,
  SET_CREATE_REGISTRY_API_ERROR_DESCRIPTION,
  SET_REGISTRY_STATUS_OCCASION,
} from '@types/mutations';
import { CREATE_REGISTRY, GET_LOCATION_SERVICE_INFO } from '@types/actions';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const CREATE_REGISTRY_ENDPOINT = '/regsvc/registry/api/v1/registry';
const LOCATION_SERVICE_ENDPOINT = '/commonlocations/v1/locations';

export default {
  namespaced: true,
  state() {
    return {
      createRegistry: {},
      createRegistryErrorState: false,
      createRegistryApiErrorResponse: null,
      createRegistryResponse: {},
      locationServiceResponse: {},
      locationErrorState: false,
      locationApiErrorResponse: null,
      locationsAvailable: false,
      registryIdExist: false,
      createRegistryLoading: false,
      createRegistryApiErrorDescription: '',
      createYourRegistryBackendError: false,
      registryStatusOccasion: '',
    };
  },
  getters: {},
  mutations: {
    [SET_CREATE_REGISTRY](state, createRegistry) {
      state.createRegistry = createRegistry;
    },
    [SET_CREATE_REGISTRY_ERROR_STATE](state, error) {
      state.createRegistryErrorState = error;
    },
    [SET_CREATE_REGISTRY_RESPONSE](state, response) {
      state.createRegistryResponse = response.data;
    },
    [SET_LOCATION_SERVICE_RESPONSE](state, response) {
      state.locationServiceResponse = response;
    },
    [SET_LOCATION_ERROR_STATE](state, payload) {
      state.locationErrorState = payload;
    },
    [SET_LOCATIONS_AVAILABLE](state, payload) {
      state.locationsAvailable = payload;
    },
    [SET_REGISTRY_ID_STATUS](state, payload) {
      state.registryIdExist = payload;
    },
    [SET_CREATE_REGISTRY_ERROR_RESPONSE](state, error) {
      state.createRegistryApiErrorResponse = error;
    },
    [SET_LOCATION_API_ERROR_RESPONSE](state, error) {
      state.locationApiErrorResponse = error;
    },
    [SET_CREATE_REGISTRY_LOADING](state, createRegistryLoading) {
      state.createRegistryLoading = createRegistryLoading;
    },
    [SET_CREATE_REGISTRY_API_ERROR_DESCRIPTION](state, createRegistryApiErrorDescription) {
      state.createRegistryApiErrorDescription = createRegistryApiErrorDescription;
    },
    [SET_CREATE_REGISTRY_BACKEND_ERROR_STATE](state, error) {
      state.createYourRegistryBackendError = error;
    },
    [SET_REGISTRY_STATUS_OCCASION](state, payload) {
      state.registryStatusOccasion = payload;
    },
  },
  actions: {
    async [CREATE_REGISTRY]({ commit, rootState }, payload) {
      commit(SET_CREATE_REGISTRY_LOADING, true);
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const { Experimentation } = await require('@vue-util/experimentation');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, Experimentation, rootState);
        const response = await $http.post(
            `${CREATE_REGISTRY_ENDPOINT}`,
            payload,
            {
              headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint, true),
            },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_CREATE_REGISTRY_RESPONSE, {});
          commit(SET_CREATE_REGISTRY_ERROR_STATE, true);
          commit(SET_CREATE_REGISTRY_API_ERROR_DESCRIPTION, isError?.[0]?.description);
          commit(SET_CREATE_REGISTRY_ERROR_RESPONSE, isError?.[0]);
        } else if (!response.data?.registryInfo?.registryId) {
          commit(SET_CREATE_REGISTRY_BACKEND_ERROR_STATE, true);
          commit(SET_CREATE_REGISTRY_ERROR_STATE, true);
          commit(SET_CREATE_REGISTRY_RESPONSE, {});
        } else {
          commit(SET_CREATE_REGISTRY_RESPONSE, response);
          commit(SET_CREATE_REGISTRY_BACKEND_ERROR_STATE, false);
          commit(SET_CREATE_REGISTRY_ERROR_STATE, false);
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(SET_CREATE_REGISTRY_RESPONSE, {});
        commit(SET_CREATE_REGISTRY_API_ERROR_DESCRIPTION, '');
        commit(SET_CREATE_REGISTRY_ERROR_STATE, true);
        commit(SET_CREATE_REGISTRY_ERROR_RESPONSE, error?.response?.data?.errors?.[0] || error?.response?.data?.error?.[0]);
      } finally {
        commit(SET_CREATE_REGISTRY_LOADING, false);
      }
    },
    async [GET_LOCATION_SERVICE_INFO]({ commit, rootState }, params) {
      const httpSource = $http.CancelToken?.source();
      const timer = setTimeout(() => {
        commit(SET_LOCATION_SERVICE_RESPONSE, {});
        commit(SET_LOCATION_ERROR_STATE, true);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const response = await $http.get(
          `${LOCATION_SERVICE_ENDPOINT}/divn/${params.orgId}/zipcode/${params.zipCode}?radius=100`,
          {
            headers: {
              'X-Client-Id':
                params.orgId === 12 || params.orgId === '12' ? 'MCOM' : 'BCOM',
              'X-Sub-Client-Id':
                params.orgId === 12 || params.orgId === '12' ? 'MCOM' : 'BCOM',
              'x-macys-apikey': rootState.envProps.xMacysApiRegistryKey,
              Accept: 'application/json',
            },
            cancelToken: httpSource.token,
          },
        );
        clearTimeout(timer);
        const locations = response?.data?.items;
        const defaultLocation = {
          name: 'Herald Square',
          storeNumber: 3,
          addresses: [{
            addrLine1: '151 West 34th Street',
            city: 'New York',
            state: 'NY',
            zipCode: '10001',
          }],
        };
        if (params.orgId === 12) {
          const validLocation = locations?.find((location) => location.attributes.find((attribute) => attribute.name.toUpperCase() === 'SL_BRIDAL' && attribute.value[0] === 'TRUE'));
          commit(SET_LOCATION_SERVICE_RESPONSE, { items: validLocation ? [validLocation] : [defaultLocation] });
        } else {
          commit(SET_LOCATION_SERVICE_RESPONSE, { items: locations?.[0] ? [locations?.[0]] : [defaultLocation] });
        }
        commit(SET_LOCATIONS_AVAILABLE, true);
      } catch (error) {
        clearTimeout(timer);
        commit(SET_LOCATION_SERVICE_RESPONSE, {});
        commit(SET_LOCATIONS_AVAILABLE, false);
        commit(SET_LOCATION_API_ERROR_RESPONSE, error);
      }
    },
  },
};
