const addToBagConfig = require('@common-vue/add-to-bag/config.mcom'); // config.mcom for mcom
const babyRegistryConfig = require('./src/config/baby-registry-mcom');

module.exports = {
  brand: 'mcom',
  offers: addToBagConfig.offers, // add to bag offers
  errorMessages: {
    registrySettings: 'There was an error loading the page. Please try again.',
    rgistrySettingApiErrorDefault: 'Something went wrong, try again.',
    giftGiverView:
      'Looks like we’re experiencing some technical difficulties. Please try again.',
    giftTracker: 'There was an error loading the page. Please try again.',
    registrantBenefitsTab: 'There was an error loading the page. Please try again.',
    myGiftTab: 'There was an error loading the page. Please try again.',
    productDetailsDrawer:
      'The server was unable to process your request. Please try again.',
    serverProcessingError:
      'The server was unable to process your request. Please try again.',
    removeItemError:
      'Something went wrong. Please try to remove the item again.',
    somethingWentWrongError: 'Something didn’t work here',
    searchRegistryError: 'There was an error loading the page. Please try again or search a different name.',
    giftTrackerApiError: 'The server was unable to process your request. Please try again',
    addToBagError: 'The server was unable to load your information. Please try again',
    registrantItemSaveChangesError: 'Something went wrong. Please try again',
    registrantHomeTabError: 'The server was unable to process your request. Please try again.',
    canvasError: 'The server was unable to process your request. Please try again.',
    locationError: 'The server was unable to load your information. Please try again',
    createYourRegistryError: 'Something went wrong. Please try again',
    searchError: 'Something went wrong. Please try again',
    createYourRegistryBackendError: "Oops! There's a temporary issue with this page.It's a quick fix if you want to wait, or feel free to continue shopping and come back here afterward. CONTINUE SHOPPING",
    giftCardOptionsUpdateError: 'Something went wrong.',
    registryManageDeactivatedError: 'Registry Has Been Deleted Due To Inactivity. Please Call An Associate For More Detail ',
  },
  suggestedCategoryList: [
    {
      name: 'Starter Ideas',
      url: '/s/registry-guide/?cm_sp=reg_hdr-_-starter-ideas-_-70529_starter-ideas',
      imagePath: 'starter_ideas_mcom.png',
    },
    {
      name: 'Kitchen',
      url: '/shop/kitchen/all-kitchen?id=291559',
      imagePath: 'Kitchen.png',
    },
    {
      name: 'Dining',
      url: '/shop/dining-entertaining/all-dining?id=245256',
      imagePath: 'Dining.png',
    },
    {
      name: 'Bed & Bath',
      url: '/shop/bed-bath/shop-all-bed-bath?id=60241',
      imagePath: 'bed_Bath.jpg',
    },
    {
      name: 'Luggage',
      url: '/shop/luggage/all-luggage?id=76877',
      imagePath: 'luggage.jpg',
    },
  ],
  allSuggestedCategoryList: [
    {
      name: 'Kitchen',
      url: '/shop/kitchen/all-kitchen?id=291559',
    },
    {
      name: 'Dining',
      url: '/shop/dining-entertaining/all-dining?id=245256',
    },
    {
      name: 'Bed & Bath',
      url: '/shop/bed-bath/shop-all-bed-bath?id=60241',
    },
    {
      name: 'Home Decor',
      url: '/shop/wedding-registry/home-decor?id=49573&cm_sp=reg_hdr-_-home-decor-_-49573_home-decor',
    },
    {
      name: 'Travel',
      url: '/shop/wedding-registry/luggage?id=16908',
    },
    {
      name: 'Bed',
      url: '/shop/wedding-registry/bed-bath/shop-all-bedding?id=20919&edge=hybrid',
    },
    {
      name: 'Bath',
      url: '/shop/wedding-registry/bed-bath/shower-accessories?id=8237',
    },
    {
      name: 'Luggage',
      url: '/shop/luggage/all-luggage?id=76877',
    },
    {
      name: 'Cleaning and Organization',
      url: '/shop/wedding-registry/kitchen/all-cleaning-organizing?id=66456',
    },
  ],
  createRegistryStep1: {
    header: "You're engaged! That has a nice ring to it.",
    subHeader: "Let's create your registry",
    imgUrlDesktop: 'create_registry_step1_desktop.jpg',
    imgUrlMobile: 'create_registry_step1_mobile@2X.jpg',
    yourName: 'Your name',
    yourFirstName: 'First name',
    yourLastName: 'Last name',
    partnersName: "Partner's name",
    partnersFirstName: 'First name',
    partnersLastName: 'Last name',
    zipCode: 'Zip code',
    eventLabel: 'When is your event?',
    addressLabel: 'Where would you like us to send your registry gifts?',
  },
  createRegistryStep2: {
    consultantHeader: 'Looking for one-on-one registry guidance?',
    consultantSubHeader: 'Our Celebrations Consultants provide a personalized experience so you can get the gifts you want.',
    consultantPreferredStorePrompt: { header: 'Connect with a consultant near:', subHeader: '' },
    consultantBoxNoStore: { header: 'No store nearby', subHeader: 'You will still be connected with a consultant' },
    preferencesPrivacyPromptTooltip: 'Public registries are visible on macys.com but private registries are not.All registries are searchable online.<br><br>You can change this any time.',
    preferencesCheckboxEmails: [
      {
        label:
          'I’d like to receive registry emails about bridal events, new products, offers, and more',
        value: 'true',
      },
    ],
    termsAndConditions: "By continuing, you agree to Macy's <a href='https://customerservice-macys.com/articles/macys-and-macyscom-notice-of-privacy-practices-2#collect.info'>Privacy Practices.</a>",
    imgUrlDesktop: 'create_registry_step2_desktop.jpg',
    imgUrlMobile: 'create_registry_step2_mobile@2X.jpg',
    consultantPreferenceText: 'Preferences',
    registryEmail: 'Registry emails',
    contactPreferenceLabel: 'How would you prefer your consultant to contact you?',
  },
  WeddingLandingPage: {
    Header: 'Wedding Registry',
    Subheader: '',
    CreateSubheader: '',
    CreateValueProp1: '10-25% off select categories',
    CreateValueProp2: 'Earn points on purchases with Star Rewards',
    CreateValueProp3: '10-20% off completion discount',
    CreateCTA: 'Get Started',
    FindSubheader: '',
    FindCTA: 'Search',
    linkRegistryCTA: 'Start here',
    linkRegistryLargeCTA: 'Start Here',
    imagePathDesktop: 'registry_landing_desktop.jpg',
    imagePathMobile: 'registry_landing_mobile@2X.jpg',
    imagePathNewWeddingDesktop: 'Macys_Registry_Entry_Desktop_Wedding.jpg',
    imagePathNewWeddingMobile: 'Macys_Registry_Entry_MEW_Wedding.jpg',
    imagePathNewBabyDesktop: 'Macys_Registry_Entry_Desktop_Baby.jpg',
    imagePathNewBabyMobile: 'Macys_Registry_Entry_MEW_Baby.jpg',
    signInCTA: 'Sign in',
    getStartedCTA: 'Get Started',
  },
  BabyLandingPage: {
    Header: 'Baby Registry',
    Subheader: '',
    CreateSubheader: '',
    CreateValueProp1: 'Get advice from Celebration Consultants',
    CreateValueProp2: 'Receive a Baby Welcome Box',
    CreateValueProp3: '10% off completion discount',
    CreateCTA: 'Get Started',
    FindSubheader: '',
    imagePathDesktopBaby: 'Macys_Baby_Registry_Registry_HP_Image_WEB.jpg',
    imagePathMobileBaby: 'Macys_Baby_Registry_HP_Image_MEW.jpg',
    signInCTA: 'Sign in',
    getStartedCTA: 'Get Started',
  },
  giftGiverRegistryView: {
    sortBy: { recentlyAdded: 'Recently Added', priceLowToHigh: 'Price (Low to High)', priceHighToLow: 'Price (High to Low)' },
    barcodeInstructions: 'Show this barcode to a Macy’s colleague for<br>assistance in store.',
    brandHeaderText: 'Macy’s WEDDINGS & REGISTRY',
    backToRegistry: 'Back to Registry',
    noItemsError: 'There are no items on this registry yet.',
    infiniteScrollExpId: '1108-21',
    bagDirectWeddingRegistryBrand: 'WEDDING REGISTRY',
    bagDirectBabyRegistryBrand: 'BABY REGISTRY',
    bagWeddingRegistryBrand: 'WEDDING REGISTRY',
    bagBabyRegistryBrand: 'BABY REGISTRY',
  },
  registrantProductCard: {
    addToBagButtonText: 'Add To Bag',
    purchasedButtonText: 'Fulfilled',
    purchasedBadgeText: 'Gifted',
    discontinuedOutOfStockButtonText: 'Discontinued',
    discontinuedOutOfStockBadgeText: 'Discontinued',
    discontinuedInStockBadgeText: 'Limited Time Availability',
    outOfStockOnlineAllChannelButtonText: 'Out of Stock',
    outOfStockOnlineAllChannelBadgeText: 'Out of Stock',
    outOfStockOnlineAllChannelBadgeLink: 'Out of Stock',
    inStoreBopsBossButtonText: 'Call To Order',
    inStoreBopsBossInfoText: 'Not available online. Call \'800‑568‑8865\' for availability',
    inStoreBopsBossInfoLink: 'Not available online. Call \'<a href="#" onclick="event.preventDefault(); document.location.href=\'tel:+1-800-568-8865\';" class="in-store-call-link">800‑568‑8865</a>\' for availability',
    backorderedBadgeText: 'Backordered',
    lowInStockInfoText: 'Low Stock',
    bigTicketItem: 'Not available online. Call \'800‑568‑8865\' for availability',
    bigTicketItemLink: 'Not available online. Call \'<a href="#" onclick="event.preventDefault(); document.location.href=\'tel:+1-800-568-8865\';" class="in-store-call-link">800‑568‑8865</a>\' for availability',
    specialOrder: 'Special Order',
    moreLikeThisCTA: 'More Like This',
    purchasedText: 'Purchased',
  },
  quickViewSlideOut: {
    myGiftsDetailsSave: 'Save Changes',
    myGiftsDetailsRemoveItem: 'REMOVE',
    myGiftsDetailsATB: 'ADD TO BAG',
  },
  giftGiverProductCard: {
    addToBagButtonText: 'Add to Bag',
    purchasedButtonText: 'Fulfilled',
    purchasedBadgeText: 'Gifted',
    discontinuedInStockInfoText: 'Limited Time Availability',
    outOfStockOnlineAllChannelButtonText: 'Out of Stock',
    outOfStockOnlineAllChannelBadgeText: 'Out of Stock',
    outOfStockOnlineAllChannelBadgeLink: 'Out of Stock',
    inStoreBopsBossButtonText: 'Call To Order',
    inStoreBopsBossInfoText: 'Not available online. Call \'800‑568‑8865\' for availability',
    inStoreBopsBossInfoLink: 'Not available online. Call \'<a href="#" onclick="event.preventDefault(); document.location.href=\'tel:+1-800-568-8865\';" class="in-store-call-link">800‑568‑8865</a>\' for availability',
    backorderedInfoText: 'Backordered',
    lowInStockInfoText: 'Low Stock',
    bigTicketItem: 'Not available online. Call \'800‑568‑8865\' for availability',
    bigTicketItemLink: 'Not available online. Call \'<a href="#" onclick="event.preventDefault(); document.location.href=\'tel:+1-800-568-8865\';" class="in-store-call-link">800‑568‑8865</a>\' for availability',
    giftCardImageUrl: 'Macy-Gift-Card.png',
  },
  dreamFundProductCard: {
    dreamFundThumbnailImg: 'Dream_Fund_Thumbnail',
  },
  shareRegitsry: {
    privacyTooltip: 'Public registries are visible on macys.com but private registries are not. All registries are searchable online. <br/> You can change this any time.',
    shareLink: 'Share this link with family & friends or add it to your website. ',
    shareEmailInstructions: 'Thank you so much for being a part of our big day! Check out our wedding registry at Macy’s.',
    weddingRegistryText: 'Wedding Registry',
  },
  findRegistry: {
    subheader: 'Search for couples by full name',
    findRegistryTitle: 'Going to a wedding? Find a registry and give the perfect gift.',
    findRegistryCTA: 'Find A Registry',
  },
  searchRegistryResult: {
    name: 'NAME',
    occasionType: 'EVENT TYPE',
    formattedOccasionDate: 'DATE',
    searchRegistryText: 'Search by name or registry ID',
    searchRegistryFullText: 'Search by full name or registry ID',
  },
  manageRegistry: {
    headerContactConsultant: 'Contact Consultant',
    tabMyGifts: 'My Registry',
    tabGiftsTracker: 'Gift Tracker',
    giftCalculatorTileHeader: 'How many gifts should I add?',
    giftCalculatorTileHeaderTooltip: 'We suggest adding about double the amount of gifts than guests so there are plenty of options to choose from.',
    giftCalculatorTileSomeGifts: 'The more the merrier',
    giftCalculatorTileSubHeader: 'Add the estimated number of guests for your event.',
    giftCalculatorTileInitialStage: 'We can help you calculate the right amount of gifts for your guests so there will be plenty to choose from.',
    consultantTileHeader: 'Need help adding gifts?',
    consultantTileBody: 'Our dedicated registry consultants are here to help, every step of the way.',
    consultantTileSecondaryCTA: 'Book An Appointment',
    consultantTileTitle: 'YOUR CONSULTANT',
    myGiftsNoItems: 'Your registry is empty. Add the stuff you love.',
    myGiftsNoItemsGiftCard: "Can't decide on gifts to add? ",
    myGiftsNoItemsGiftCardAdd: 'Add A Gift Card',
    myGiftsCategoryCarouselHeader: 'Need suggestions? We have ideas',
    myGiftsDetailsRemoveItem: 'Remove Item',
    myGiftsBackordered: 'Backordered',
    myGiftsOutofStock: '',
    completionBannerHeader: 'Didn’t get everything on your registry?',
    completionBcomBannerSaveText: 'Save 20%',
    completionBcomBannerSubHeader: 'on almost all remaining items for the next six months.',
    completionBannerSubHeader: 'Save 10-20% on almost all remaining items for six months after your event date.',
    completionBannerCTA: 'Details',
    completionBannerPrint: 'Print',
    completionBannerImageWeddingDesktop: 'Completion_Banner_desktop.jpg',
    completionBannerImageWeddingMobile: 'Completion_Banner_mobile.jpg',
    completionBannerImageBabyDesktop: 'mcom_baby_completion_banner_desktop.png',
    completionBannerImageBabyMobile: 'mcom_baby_completion_banner.jpg',
    popularRegisteredItemsHeader: 'Add popular registry gifts',
    completionModalHeader: 'Your completion offer',
    completionModalSubHeader: 'Save 10-20% on almost all remaining items for six months after your event date.',
    completionModalOnlineHeader: 'Online',
    completionModalOnlineBodyFirstPhrase: 'Use promo code',
    completionModalOnlineBodySecondPhrase: 'at checkout. The code is saved in your bag for easy use!',
    completionModalInStoreHeader: 'In Store',
    completionModalInStoreBody: 'Show this barcode to a colleague.',
    preOccasionMedalliaFormId: 12485,
    postOccasionMedalliaFormId: 12486,
    headerProfileIconWedding: 'rings.svg',
    headerProfileIconBaby: 'MCOM_occasion_icon.jpg',
    surveyRibbon: 'Give Feedback',
    surveyCopyCTA: { title: 'Give feedback & help improve the registry', link: 'Answer a few questions', subtitle: '' },
    giftTrackerNoContentMessage: 'When gifts are purchased from your registry, you’ll see them displayed here.',
    myGiftsNoCategoryText1: 'There’s nothing from this category on your registry yet. To see items you’re registered for, adjust your filters.',
    myGiftsNoCategoryText2: 'Discover',
    myGiftsNoCategoryText3: ' items for your registry.',
    myGiftsConjunction: ' or ',
    giftCalculatorTilePrimaryCTA: 'Add More Gifts',
    shareRegistryTitle: 'Share Your Registry',
    daysAwayText: 'Days away',
    giftsPurchased: 'Gifts purchased',
    giftsAdded: 'Gifts added',
    barcodeInstructionsMyGifts: 'Show this barcode to a Macy’s colleague for<br>assistance in store.',
    starterIdeasLink: '/s/registry-guide/?cm_sp=reg_catsplash_registry_manager_n-_-reg_w_leftnav_tools-_-Starter-Ideas',
    babyStarterIdeasLink: '/shop/kids-clothes/baby-products?id=187458&cm_sp=us_hdr-_-kids-_-187458_all-baby_COL1',
    registryPrivacyInfoMessage: 'When you’re ready, set your registry as public to share it with family and friends.',
    eGiftCardUpcNumber: 478000239643,
    pGiftCardUpcNumber: 478000239605,
    registryGiftCardTileHeader1: 'Register for Gift Cards',
    registryGiftCardTileHeader2: 'You’re registered for Gift Cards',
    registryGiftCardTileQuestion: 'Get exactly what you want',
    registryGiftCardBody1: 'Macy’s Gift Cards give you the flexibility to buy the things you need once you’re ready.',
    registryGiftCardBody2: 'Your loved ones can choose the amount.',
    registryGiftCardTextCTA1: 'Add to Registry',
    registryGiftCardTextCTA2: 'Edit Gift Card Options',
    giftCardCheckboxValue: 'Register for Macy’s gift cards',
    registryPostCompletionMessage: 'You can no longer add new items to this registry, but you may still delete or purchase any items you like. To request additional gifts,',
    guestCountTileHeader: 'Number of guests',
    guestStateTileHeader: 'How many gifts should I add?',
    giftCalculatorRecommendedGift: 'Recommended amount of gifts for',
    giftCardGiftsSuggested: 'gifts suggested',
    giftTrackerSortByValues: [
      {
        value: 'sortByDate_desc',
        displayValue: 'Purchased: New to Old',
      },
      {
        value: 'sortByDate_asc',
        displayValue: 'Purchased: Old to New',
      },
      {
        value: 'sortByName_asc',
        displayValue: 'Gift Giver Name (A-Z)',
      },
      {
        value: 'sortByName_desc',
        displayValue: 'Gift Giver Name (Z-A)',
      },
    ],
    giftTrackerGiftReceivedActionValues: [
      {
        label: 'Gift Received',
        value: 'giftReceived',
      },
    ],
    giftTrackerThankyouSentActionValues: [
      {
        label: 'Thank You Sent',
        value: 'thankyouSent',
      },
    ],
    giftsAddedText: 'Done!',
    bagDirectWeddingRegistryBrand: 'WEDDING REGISTRY',
    bagDirectBabyRegistryBrand: 'BABY REGISTRY',
    bagWeddingRegistryBrand: 'WEDDING REGISTRY',
    bagBabyRegistryBrand: 'BABY REGISTRY',
    backToRegistry: 'Back to Registry',
  },
  registrySettingsText: {
    registryEmailsCheckbox: [
      {
        label: 'Email me about exciting events, helpful tools, and exclusive registry offers.',
        value: true,
      },
    ],
    privacyPreferenceOptions: [
      {
        label: 'Public',
        value: 'Public',
      },
      {
        label: 'Private',
        value: 'Private',
      },
    ],
    currentShippingAddress: 'Registry shipping address',
    contactInformation: 'Contact Information',
    eventDetails: 'Event Details',
    shippingAddress: 'Shipping Address',
    giftCards: 'Gift cards',
    guestCountLabel: 'Number of guests:',
    eventLabel: 'Event:',
    visibilityStatusLabel: 'Visibilty status',
    giftCardsRegisteredLabel: 'Registered for gift cards:',
    receiveGiftCardsLabel: 'Receive gift cards by:',
    guestCountTileLabel: 'Number of guests',
  },
  linkRegistryText: {
    linkTooltip: 'Public registries are visible on macys.com but private registries are not. All registries are searchable online. You can change this any time.',
    linkCta: 'Continue',
    makeThisPublicText: 'Make this Public',
    whatsThis: 'What’s This?',
    toolTipTitle: 'Public or Private View',
  },
  giftCardSlideOut: {
    myGiftsDetailsATB: 'ADD TO BAG',
  },
  giftCardProductCard: {
    brand: 'Macy’s',
    imageUrl: 'https://slimages.macysassets.com/is/image/MCY/products/2/optimized/20880512_fpx.tif?qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0&fmt=jpeg&wid=302&hei=378',
    registrantInfo: 'Starting at $10',
    giftGiverInfo: 'Can’t decide what to buy? Go for a gift card.',
    registrantCTA: 'Edit',
    giftGiverCTA: 'Add To Bag',
  },
  babyRegistryConfig,
  registryBonusGifts: [],
};
