<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    id="soasta_pageinfo"
    style="display: none"
    v-html="soastaTag"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SoastaPageInfo',
  computed: {
    ...mapState(['envProps']),
    soastaTag() {
      return !this.envProps
        ? ''
        : `<site>${this.envProps.soastaPageInfo.appSite}</site>
          <region>${this.envProps.soastaPageInfo.appSite}</region>
          <type>${this.envProps.soastaPageInfo.appType}</type>
          <timestamp>${this.envProps.soastaPageInfo.timestamp}</timestamp>
          <release>${this.envProps.soastaPageInfo.appRelease}</release>
          <releasedate>${this.envProps.soastaPageInfo.releaseDate}</releasedate>
          <version>${this.envProps.soastaPageInfo.appVersion}</version>`;
    },
  },
};
</script>
