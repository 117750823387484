import { v4 as generateUUIDv4 } from 'uuid';
import { Cookie } from '@vue-util/cookie';
import { generateHMACKey } from '@utils/hmacKeyCreation';

const httpGetNonProtectedAPIHeaders = (rootState = {}, optionalHeaders = {}) => ({
  'x-macys-request-id': generateUUIDv4(),
  'x-macys-apikey': rootState?.envProps?.xMacysApiRegistryKey,
  'x-macys-dca': Cookie.get('dca'),
  Accept: 'application/json',
  ...optionalHeaders,
});

const httpGetProtectedAPIHeaders = (rootState = {}, optionalHeaders = {}) => ({
  macys_online_uid: encodeURIComponent(Cookie.get(rootState?.envProps?.entryPoint.toUpperCase() === 'BCOM' ? 'bloomingdales_online_uid' : 'macys_online_uid')),
  host: rootState?.envProps?.siteDomain,
  'x-macys-securitytoken':
  encodeURIComponent(Cookie.get('secure_user_token')),
  ...(!rootState?.envProps?.siteDomain?.includes('macys') && !rootState?.envProps?.siteDomain?.includes('bloomingdales')) && { 'x-macys-client-host': rootState?.envProps?.siteDomain?.split('.')[1] },
  ...httpGetNonProtectedAPIHeaders(rootState, optionalHeaders),
});

const httpUpdateAPIHeaders = (rootState = {}, hmacKeyPayload, optionalHeaders = {}, onlineGuid = false) => ({
  'Content-Type': 'application/json',
  HMAC: generateHMACKey(hmacKeyPayload, rootState?.envProps?.hmacRegistryKey),
  ...httpGetProtectedAPIHeaders(rootState, optionalHeaders),
  ...(onlineGuid) && { macys_online_guid: encodeURIComponent(Cookie.get(rootState?.envProps?.entryPoint.toUpperCase() === 'BCOM' ? 'bloomingdales_online_guid' : 'macys_online_guid')) },
});

export {
  httpGetNonProtectedAPIHeaders,
  httpGetProtectedAPIHeaders,
  httpUpdateAPIHeaders,
};
