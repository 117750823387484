import {
  UPDATE_GIFT_CARD_OPTIONS,
  SHOW_GIFT_CARD_OPTIONS_SLIDEOUT,
} from '@types/actions';
import {
  SET_GIFT_CARD_OPTIONS,
  SET_GIFT_CARD_OPTIONS_UPDATE_ERROR,
  SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS,
  SET_GIFT_CARD_OPTIONS_UPDATE_ERROR_DESCRIPTION,
  SET_REFRESH_REGISTRANT_MY_GIFT,
} from '@types/mutations';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const REGISTRANT_GIFT_CARD_ENDPOINT = '/regsvc/registry/api/v1/registry';

export default {
  namespaced: true,
  state: {
    giftCardOptionsUpdateError: false,
    giftCardOptionsUpdateSuccess: false,
    giftCardOptionsUpdateErrorDescription: '',
    refreshRegistrantMyGift: false,
  },
  getters: {},
  mutations: {
    [SET_GIFT_CARD_OPTIONS_UPDATE_ERROR](state, data) {
      state.giftCardOptionsUpdateError = data;
    },
    [SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS](state, data) {
      state.giftCardOptionsUpdateSuccess = data;
    },
    [SET_GIFT_CARD_OPTIONS_UPDATE_ERROR_DESCRIPTION](state, data) {
      state.giftCardOptionsUpdateErrorDescription = data;
    },
    [SET_REFRESH_REGISTRANT_MY_GIFT](state, data) {
      state.refreshRegistrantMyGift = data;
    },
  },
  actions: {
    async [UPDATE_GIFT_CARD_OPTIONS]({ commit, rootState }, { payload, module }) {
      try {
        commit(SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS, false);
        commit(SET_GIFT_CARD_OPTIONS_UPDATE_ERROR, false);
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const { Experimentation } = await require('@vue-util/experimentation');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, Experimentation, rootState);
        const response = await $http.put(`${REGISTRANT_GIFT_CARD_ENDPOINT}`,
          payload,
          {
            headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
          });
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_GIFT_CARD_OPTIONS_UPDATE_ERROR, true);
          commit(SET_GIFT_CARD_OPTIONS_UPDATE_ERROR_DESCRIPTION, isError?.[0]?.description);
        } else {
          commit(`${module}/${SET_GIFT_CARD_OPTIONS}`, payload.basicInfoUpdate, { root: true });
          commit(SET_GIFT_CARD_OPTIONS_UPDATE_SUCCESS, true);
          commit(SET_GIFT_CARD_OPTIONS_UPDATE_ERROR_DESCRIPTION, '');
        }
      } catch (error) {
        SoftSignInValidation(error);
        commit(SET_GIFT_CARD_OPTIONS_UPDATE_ERROR, true);
        commit(SET_GIFT_CARD_OPTIONS_UPDATE_ERROR_DESCRIPTION, error?.response?.data?.errors?.[0]?.description);
      }
    },
    async [SHOW_GIFT_CARD_OPTIONS_SLIDEOUT]({ commit }, { refresh }) {
      commit('slideoutStore/TOGGLE', 'gift-card-options', { root: true });
      commit(SET_REFRESH_REGISTRANT_MY_GIFT, refresh);
    },
  },
};
