import { GET_CANVAS } from '@types/actions';
import {
  SET_CANVAS,
  SET_CANVAS_ERROR_STATE,
  SET_CANVAS_API_ERROR_RESPONSE,
} from '@types/mutations';

const $http = require('@vue-util/http');

const CANVAS_ENDPOINT = '/xapi/homepage/v1/canvas';

export default {
  namespaced: true,
  state() {
    return {
      canvasObject: {},
      canvasErrorState: false,
      canvasApiErrorResponse: null,
    };
  },
  getters: {},
  mutations: {
    [SET_CANVAS](state, canvasObject) {
      state.canvasObject = canvasObject;
    },
    [SET_CANVAS_ERROR_STATE](state, canvasErrorState) {
      state.canvasErrorState = canvasErrorState;
    },
    [SET_CANVAS_API_ERROR_RESPONSE](state, error) {
      state.canvasApiErrorResponse = error;
    },
  },
  actions: {
    async [GET_CANVAS]({ commit, rootState }, params) {
      const shoppingMode = rootState.base.shoppingModeForAemAsSiteEnabled ? 'SITE' : 'WEDDING_REGISTRY';
      try {
        const response = await $http.get(`${CANVAS_ENDPOINT}?_shoppingMode=${shoppingMode}&page=${params.page}&_deviceType=${params.deviceType}`, {
          headers: {
            ...(shoppingMode === 'SITE' && { 'X-Macys-SubClientId': 'registry' }),
          },
        });
        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_CANVAS_ERROR_STATE, true);
          commit(SET_CANVAS_API_ERROR_RESPONSE, isError?.[0]);
        } else {
          commit(SET_CANVAS, response.data);
          commit(SET_CANVAS_ERROR_STATE, false);
        }
      } catch (error) {
        commit(SET_CANVAS, {});
        commit(SET_CANVAS_ERROR_STATE, true);
        commit(SET_CANVAS_API_ERROR_RESPONSE, error);
      }
    },
  },
};
