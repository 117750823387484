<template>
  <div
    id="registry-container"
    class="grid-container registry-home-container align-center"
  >
    <RouterView
      :key="$route.fullPath"
    />

    <SoastaPageInfo />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { loadTagLibrary } from '@vue-util/tagManager';
import SoastaPageInfo from '@components/SoastaPageInfo';

export default {
  name: 'App',
  components: {
    SoastaPageInfo,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['envProps']),
  },
  async mounted() {
    loadTagLibrary(this.envProps.entryPoint, this.envProps.tagMngrEnv, true);

    // Auto-tagging
    const { ExperimentationTags } = require('@vue-util/experimentation');
    await ExperimentationTags.fireAdobeTags();
  },
  methods: {},
};
</script>
<style lang="scss">
@import "~appSCSS";
@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  @page {
    margin: 0mm;
    size: A3;
  }
}
.registry-home-container {
  padding-right: rem-calc(20);
  padding-left: rem-calc(20);

  @include breakpoint(medium only) {
    padding-right: rem-calc(20) !important;
    padding-left: rem-calc(20) !important;
  }

  @include breakpoint(large only) {
    padding-right: rem-calc(20) !important;
    padding-left: rem-calc(20) !important;
  }
}
</style>
