module.exports = {
  createRegistryStep1: {
    header: 'Congratulations!',
    subHeader: 'Time to start the baby registry of your dreams',
    eventLabel: 'Arrival date',
    imgUrlDesktop: 'Macys_Baby_Registry_Create_a_Registry_Step1_WEB.jpg',
    imgUrlMobile: 'Macys_Baby_Registry_Create_a_Registry_Step1_MEW.jpg',
    partnersName: "Partner's name (Optional)",
    addressLabel: 'Where would you like your gifts sent?',
  },
  createRegistryStep2: {
    consultantHeader: 'Need help adding gifts?',
    consultantSubHeader: 'Our Celebrations Consultants provide a personalized experience so you can get the gifts you want.',
    imgUrlDesktop: 'Macys_Baby_Registry_Create_a_Registry_Step2_WEB.jpg',
    imgUrlMobile: 'Macys_Baby_Registry_Create_a_Registry_Step2_MEW.jpg',
    preferencesCheckboxEmails: [
      {
        label: 'Email me about upcoming events, helpful tools, and exclusive registry offers.',
        value: 'true',
      },
    ],
    contactPreferenceLabel: 'How do you want your consultant to contact you?',
  },
  findRegistry: {
    findRegistryTitle: 'Shopping for someone else?',
    findRegistryCTA: 'Find A Registry',
  },
  giftGiverRegistryView: {
    barcodeInstructions: 'Show this barcode to a Macy’s associate for<br>in-store assistance.',
  },
  manageRegistry: {
    barcodeInstructionsMyGifts: 'Show this barcode to a Macy’s associate for<br>in-store assistance.',
    completionModalInStoreBody: 'Show this barcode to a colleague.',
    completionBannerSubHeader: 'Save 10% on almost all remaining items 60 days before your arrival date & 6 months after.',
    completionModalSubHeader: 'Save 10% on almost all remaining items 60 days before your arrival date & 6 months after.',
    giftCalculatorTileHeader: 'Recommended number of gifts',
    giftCalculatorTileInitialStage: 'We’ll help calculate the recommended number of gifts based on how many people you’re sharing your registry with.',
    consultantTileBody: 'Our Celebrations Consultants provide a personalized experience so you can get the gifts you want.',
    guestCountTileHeader: 'How many gifts should I add?',
    giftCalculatorTileSubHeader: 'We’ll help calculate the recommended number of gifts based on how many people you’re sharing your registry with.',
    guestCountTileLabel: 'Number of people',
    guestStateTileHeader: 'How many gifts should I add?',
    giftCalculatorRecommendedGift: 'Based on',
    giftCardGiftsSuggested: 'Suggested gifts',
    giftCalculatorTilePrimaryCTA: 'Add Registry Items',
    giftCalculatorTileSomeGifts: 'The more the merrier',
    headerContactConsultant: 'Contact Consultant',
    consultantTileTitle: 'YOUR CONSULTANT',
  },
  shareRegitsry: {
    shareLink: 'Share this link with family & friends or add it to your website',
    babyRegistryText: 'Baby Registry',
  },
  registrySettingsText: {
    currentShippingAddress: 'Gifts will be sent to this address.',
    guestCountLabel: 'Number of people:',
    eventLabel: 'Registry type:',
    visibilityStatusLabel: 'Visibility',
    giftCardsRegisteredLabel: 'Registered for gift cards:',
    receiveGiftCardsLabel: 'Receive by:',
  },
  suggestedCategoryList: [
    {
      name: 'Baby Gear',
      url: '/shop/kids-clothes/baby-strollers-gear?id=58376&edge=hybrid&cm_sp=back_functionality-_-breadcrumbs-_-All%20Baby%20Gear%20&%20Essentials',
      imagePath: 'baby_gear.png',
    },
    {
      name: 'Clothing',
      url: '/shop/kids-clothes/baby-clothing?id=64761&edge=hybrid&cm_sp=back_functionality-_-breadcrumbs-_-All%20Baby',
      imagePath: 'clothing.png',
    },
    {
      name: 'Feeding, Nursing, Teething',
      url: '/shop/kids-clothes/nursing-feeding?id=104544&cm_sp=c2_1111US_catsplash_kids-baby-shop-all-baby-baby-gear-%26-essentials-all-baby-gear-%26-essentials-_-row1-_-icon_nursing-%26-feeding&edge=hybrid&rbe=rbe_visualnav',
      imagePath: 'feeding.png',
    },
    {
      name: 'Sleeping',
      url: '/shop/kids-and-baby-room/all-baby-room/Kids-and-baby-room/all-baby-room?id=228431',
      imagePath: 'sleeping.png',
    },
    {
      name: 'Activity & Play',
      url: '/shop/kids-clothes/playtime-activity?id=190458&cm_sp=c2_1111US_catsplash_kids-baby-shop-all-baby-baby-gear-%26-essentials-all-baby-gear-%26-essentials-_-row1-_-icon_playtime-%26-activities&edge=hybrid&rbe=rbe_visualnav',
      imagePath: 'activity.png',
    },
  ],
};
