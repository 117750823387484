import { Cookie } from '@vue-util/cookie';

export const SoftSignInValidation = (error = {}) => {
  if (error.response?.status === 401) {
    Cookie.set('SignedIn', 0);
    Cookie.set('FORWARDPAGE_KEY', window.location.href);
    window.location.assign('/account/signin?fromRegistry=true');
  }
};
export default {
  SoftSignInValidation,
};
