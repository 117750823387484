const validateRegistryId = (value) => {
  if (/^[0-9]+$/.test(value)) {
    return true;
  }
  return false;
};

const getUnformattedDate = (date) => {
  const unformattedDate = typeof date === 'string' ? date.replace(/-/g, '/')?.replace(/T.+/, '') : date;
  return new Date(unformattedDate);
};

const registryFormatDate = (d, shortMonth = false) => {
  const date = getUnformattedDate(d);
  const day = date.getDate();
  const month = shortMonth ? date.toLocaleString('default', { month: 'short' }) : date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${month} ${day}, ${year}`;
};

const createRegistryFormatDate = (d) => {
  const date = getUnformattedDate(d);
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let month = date.toLocaleString('default', { month: 'numeric' });
  month = month < 10 ? `0${month}` : month;
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${year}-${month}-${day}`;
};

const registrySettingFormatDate = (d) => {
  const date = getUnformattedDate(d);
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let month = date.toLocaleString('default', { month: 'numeric' });
  month = month < 10 ? `0${month}` : month;
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${month}/${day}/${year}`;
};

const registrantFormatDate = (d) => {
  const date = getUnformattedDate(d);
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let month = date.toLocaleString('default', { month: 'numeric' });
  month = month < 10 ? `0${month}` : month;
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${month}${day}${year}`;
};

const dateSortFormatDate = (d) => {
  const date = getUnformattedDate(d);
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let month = date.toLocaleString('default', { month: 'numeric' });
  month = month < 10 ? `0${month}` : month;
  const year = date.toLocaleString('default', { year: 'numeric' });
  return Number(`${year}${month}${day}`);
};

const paginate = (items, pageSize, pageNumber) => items?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

const infiniteScrollPaginate = (items, totalItems, pageSize, pageNumber) => [...items, ...totalItems?.slice((pageNumber - 1)
  * pageSize, pageNumber * pageSize)];

const createRegistryTagFormatDate = (d = '') => {
  const date = d.split('-');
  return `${date[1]}${date[2]}${date[0]}`;
};

export {
  getUnformattedDate,
  validateRegistryId,
  registryFormatDate,
  paginate,
  createRegistryFormatDate,
  registrySettingFormatDate,
  registrantFormatDate,
  dateSortFormatDate,
  infiniteScrollPaginate,
  createRegistryTagFormatDate,
};
