import { modalOverlayStore } from '@atomic-ui/modalOverlay';
import { locations } from '@common-vue/locator/src/store';
import { addToBagStore } from '@common-vue/add-to-bag';
import { FilteringFacetsStore } from '@common-vue/filtering-facets';
import { recommendationStore } from '@common-vue/pros-collection';
import { moreLikeThisStore } from '@common-vue/product-thumbnail';
import { wishlistStore } from '@common-vue/add-to-wishlist';
import quickView from '@components/store_modules/quickView';
import headerBanner from '@components/store_modules/headerBanner';
import searchRegistry from '@components/store_modules/searchRegistry';
import giftGiverRegistryView from '@components/store_modules/giftGiverRegistryView';
import createRegistry from '@components/store_modules/createRegistry';
import RegistrySettings from '@components/store_modules/registrySettings';
import customerInfo from '@components/store_modules/customerInfo';
import registrantGiftTracker from '@components/store_modules/registrantGiftTracker';
import registrantMyGiftTab from '@components/store_modules/registrantMyGiftTab';
import canvas from '@components/store_modules/canvas';
import registrantHome from '@components/store_modules/registrantHome';
import editGuestCount from '@components/store_modules/editGuestCount';
import linkRegistry from '@components/store_modules/linkRegistry';
import giftCard from '@components/store_modules/giftCard';
import base from './components/store_modules/base';
import barcode from './components/store_modules/barcode';

export default {
  namespaced: true,
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    addToBagStore,
    quickView,
    headerBanner,
    searchRegistry,
    giftGiverRegistryView,
    createRegistry,
    RegistrySettings,
    customerInfo,
    registrantGiftTracker,
    modalOverlayStore,
    registrantMyGiftTab,
    registrantHome,
    editGuestCount,
    locations,
    linkRegistry,
    // By importing in the Filtering Facets store, we are also
    // importing the slideoutStore module.
    // If this is deleted, add back in the slideoutStore module
    FilteringFacetsStore,
    canvas,
    giftCard,
    base,
    barcode,
    recommendationStore,
    wishlistStore,
    moreLikeThisStore,
  },
};
