import {
  SET_LINK_REGISTRY, SET_LINK_ERROR_STATE, SET_LINK_REGISTRY_API_ERROR_DESCRIPTION,
  SET_LINK_REGISTRY_API_ERROR_RESPONSE,
} from '@types/mutations';
import { LINK_REGISTRY, SET_LINK_SUCCESS_STATE } from '@types/actions';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';
import { generateLogin365FingerPrint } from '@utils/PersistentLoginUtil';

const $http = require('@vue-util/http');

const LINK_REGISTRY_ENDPOINT = '/regsvc/registry/api/v1/registry/link';

export default {
  namespaced: true,
  state() {
    return {
      linkRegistry: null,
      linkStatusError: false,
      linkStatusSuccess: false,
      linkRegistryErrorDescription: '',
      linkRegistryApiErrorResponse: {},
    };
  },
  getters: {},
  mutations: {
    [SET_LINK_REGISTRY](state, payload) {
      state.linkRegistry = payload;
    },
    [SET_LINK_ERROR_STATE](state, payload) {
      state.linkStatusError = payload;
    },
    [SET_LINK_SUCCESS_STATE](state, payload) {
      state.linkStatusSuccess = payload;
    },
    [SET_LINK_REGISTRY_API_ERROR_DESCRIPTION](state, linkRegistryErrorDescription) {
      state.linkRegistryErrorDescription = linkRegistryErrorDescription;
    },
    [SET_LINK_REGISTRY_API_ERROR_RESPONSE](state, linkRegistryApiErrorResponse) {
      state.linkRegistryApiErrorResponse = linkRegistryApiErrorResponse;
    },
  },
  actions: {
    async [LINK_REGISTRY]({ commit, rootState }, payload) {
      try {
        const { PersistentLoginUtil } = await require('@vue-util/persistentLogin');
        const { Experimentation } = await require('@vue-util/experimentation');
        const login365FingerPrint = await generateLogin365FingerPrint(PersistentLoginUtil, Experimentation, rootState);
        const response = await $http.post(
          `${LINK_REGISTRY_ENDPOINT}`,
          payload,
          {
            headers: httpUpdateAPIHeaders(rootState, payload, login365FingerPrint),
          },
        );
        const isError = response?.data?.errors || response?.data?.error;
        if (response?.data?.registryInfo) {
          commit(SET_LINK_REGISTRY_API_ERROR_DESCRIPTION, '');
          commit(SET_LINK_ERROR_STATE, false);
          commit(SET_LINK_REGISTRY, response.data);
          commit(SET_LINK_SUCCESS_STATE, true);
        } else {
          commit(SET_LINK_ERROR_STATE, true);
          commit(SET_LINK_SUCCESS_STATE, false);
          commit(SET_LINK_REGISTRY, {});
          commit(SET_LINK_REGISTRY_API_ERROR_DESCRIPTION, isError?.[0]?.description);
          commit(SET_LINK_REGISTRY_API_ERROR_RESPONSE, isError?.[0]);
        }
      } catch (error) {
        const errorResponse = error?.response?.data?.errors?.[0] || error?.response?.data?.error?.[0];
        SoftSignInValidation(error);
        commit(SET_LINK_ERROR_STATE, true);
        commit(SET_LINK_SUCCESS_STATE, false);
        commit(SET_LINK_REGISTRY, {});
        commit(SET_LINK_REGISTRY_API_ERROR_DESCRIPTION, errorResponse?.description);
        commit(SET_LINK_REGISTRY_API_ERROR_RESPONSE, errorResponse);
      }
    },
  },
};
