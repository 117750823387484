import { GET_REGISTRY_INFO } from '@types/actions';
import {
  SET_REGISTRY_INFO, SET_BARCODE, SET_GIFT_GIVER_VIEW_LOADING, SET_GIFT_GIVER_VIEW_ERROR, SET_GIFT_GIVER_API_ERROR_RESPONSE,
} from '@types/mutations';
import { httpGetNonProtectedAPIHeaders } from '@utils/APIUtil';

const $http = require('@vue-util/http');

const GIFT_GIVER_REGISTRY_ENDPOINT = '/regsvc/registry/api/v1/preference/getPreference';

export default {
  namespaced: true,
  state() {
    return {
      registryInfo: {},
      registryBarCode: '',
      giftGiverViewLoading: true,
      giftGiverApiErrorResponse: null,
      error: false,
    };
  },
  getters: {},
  mutations: {
    [SET_REGISTRY_INFO](state, registryInfo) {
      state.registryInfo = registryInfo;
    },
    [SET_BARCODE](state, registryBarCode) {
      state.registryBarCode = registryBarCode;
    },
    [SET_GIFT_GIVER_VIEW_LOADING](state, giftGiverViewLoading) {
      state.giftGiverViewLoading = giftGiverViewLoading;
    },
    [SET_GIFT_GIVER_VIEW_ERROR](state, error) {
      state.error = error;
    },
    [SET_GIFT_GIVER_API_ERROR_RESPONSE](state, error) {
      state.giftGiverApiErrorResponse = error;
    },
  },
  actions: {
    async [GET_REGISTRY_INFO]({ commit, rootState }, params) {
      commit(SET_GIFT_GIVER_VIEW_LOADING, true);
      const httpSource = $http.CancelToken.source();
      const timer = setTimeout(() => {
        commit(SET_REGISTRY_INFO, {});
        commit(SET_GIFT_GIVER_VIEW_ERROR, true);
        commit(SET_GIFT_GIVER_VIEW_LOADING, false);
        httpSource.cancel();
      }, rootState.envProps.apiTimeout);
      try {
        const response = await $http.get(`${GIFT_GIVER_REGISTRY_ENDPOINT}?orgId=${params.orgId}&${params.registryGUID ? `registryGUID=${params.registryGUID}` : `registryId=${params.registryId}`}&productInfoFlag=true`, {
          headers: httpGetNonProtectedAPIHeaders(rootState),
          cancelToken: httpSource.token,
        });
        clearTimeout(timer);

        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_REGISTRY_INFO, {});
          commit(SET_GIFT_GIVER_VIEW_ERROR, true);
          commit(SET_GIFT_GIVER_API_ERROR_RESPONSE, isError);
        } else {
          commit(SET_REGISTRY_INFO, response.data?.registryInfo);
          commit(SET_BARCODE, response.data?.registryInfo.barCodeNumber);
          commit(SET_GIFT_GIVER_VIEW_ERROR, false);
        }
      } catch (error) {
        clearTimeout(timer);
        commit(SET_REGISTRY_INFO, {});
        commit(SET_GIFT_GIVER_VIEW_ERROR, true);
        commit(SET_GIFT_GIVER_API_ERROR_RESPONSE, error?.data?.errors || error?.data?.error);
      } finally {
        commit(SET_GIFT_GIVER_VIEW_LOADING, false);
      }
    },
  },
};
